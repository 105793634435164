import imageOne from '../../assets/images/service/servicebanner2.jpg'
import imageTwo from '../../assets/images/service/servicebanner1.jpg'
import imageThree from '../../assets/images/service/servicebaner3.jpg'

export const BannerData = [
    {
        title: 'Digital Vehicle and Driver Inspection Checklist Survey',
        price: 'Software Solutions Services',
        path: '/services/2',
        image: imageOne,
        alt: 'Digital Vehicle and Driver Inspection Checklist Survey',
        label: 'Know More'
    },
    {
        title: 'Transport and Logistics Management Software',
        price: 'Software Solutions Services',
        path: '/services/3',
        image: imageTwo,
        alt: 'Transport and Logistics Management Software',
        label: 'Know More'
    },
    {
        title: 'Warehouse & Inventory Management Software',
        price: 'Software Solutions Services',
        path: '/services/8',
        image: imageThree,
        alt: 'Warehouse & Inventory Management Software',
        label: 'Know More'
    },
  
]


// services

// vehicle tracking solution
//
