import imageOne from '../../assets/images/test.png'
import imageTwo from '../../assets/images/1.png'
import imageThree from '../../assets/images/client4.jpg'


export const slides = [
    {
        name: 'CEO',
        testimonial: `We rely on WOM Solutions' logistics and transport management software daily. It has simplified our route planning and improved on-time deliveries.`,
        city: 'Bhagel Transport',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: 'CEO',
        testimonial: `The digital vehicle inspection checklists are thorough and easy to use. They ensure our compliance with safety regulations and keep our fleet running smoothly`,
        city: 'Bhagel Transport',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: 'CEO',
        testimonial: `The software development expertise at WOM Solutions is impressive. They have consistently delivered innovative solutions that have positively impacted our operations.`,
        city: 'Bhagel Transport',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: 'CEO',
        testimonial: `Their commitment to client collaboration is evident in every interaction. WOM Solutions truly feels like an extension of our team.`,
        city: 'Bhagel Transport',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: 'CEO',
        testimonial: 'When our retail customers need software changes to meet new market requirements we turn to the professionals at WOM Solutions to ensure timely delivery. They understand the nuances of our solutions and always deliver the highest quality work.',
        city: 'Bhagel Transport',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: 'CTO',
        testimonial: 'I have been involved in big projects for major corporations and the team at WOM Solutions is the best that I have worked with. They are timely, efficient and use best practices in their development work.  ',
        city: 'Smart Shoppe Goa',
        image: imageTwo,
        alt: 'ImageTwo',
    },
    {
        name: 'Chairman',
        testimonial: 'We have been working together with WOM Solutions for more than 4 years now and it is has been a very positive experience for me and my company. WOM Solutions has been a trusted partner.',
        city: 'Travenza Tours',
        image: imageTwo,
        alt: 'Imagethree',
    },
]