import blogImg from "../images/service2.jpg";
import softwareDevelopment from "../images/service/software-development.jpg";
import androidDevelopment from "../images/service/android-development.jpg";
import ecommerce from "../images/service/ecommerce.jpg";
import erpSoftware from "../images/service/erpsoftware.jpg";
import vehicleTracking from "../images/service/vehicletracking.jpg"
import inventory from "../images/service/inventory.jpg";
import gpsVehicle from "../images/service/gps-vehicle.jpg";
import schoolerp from "../images/service/erp.jpg";



export const blogs = [
    {
        id: 1,
        image: softwareDevelopment,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds done by rohit',
        more: 'Read More...',
    },
    {
        id: 2,
        image: schoolerp,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds developed for wom solutions',
        more: 'Read More...',
    },
    {
        id: 3,
        image: inventory,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds using javascript technologies',
        more: 'Read More...',
    },
    {
        id: 4,
        image: ecommerce,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds by Reactjs and Scss',
        more: 'ecomm',
    },
    {
        id: 5,
        image: gpsVehicle,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds started in 2022',
        more: '',
    },
    {
        id: 6,
        image: vehicleTracking,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds provides best services',
        more: 'Read More...',
    },
    {
        id: 7,
        image: erpSoftware,
        date: '10 JAN. 2022',
        description: 'We Provide a marketplace platform for all hospitals, more content are good, website design are best now we are doing the best in this tehcnology and nobody cant beat us now becuase we always dedicted towards our work.',
        name: 'Brilliant Minds solved major industrial issues',
        more: 'Read More...',
    },
    
]